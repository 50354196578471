import { combineReducers } from 'redux';
import { authReducer, messageReducer, customerReducer, activityReducer, profileReducer, cmdReducer, coachingReducer }  from "../_reducers";



const rootReducer = combineReducers({
    authReducer,
    messageReducer,
    customerReducer,
    activityReducer,
    profileReducer,
    cmdReducer,
    coachingReducer
})

export default rootReducer;