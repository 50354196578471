
export const loginStep = {
    STEP_EMAIL: "email",
    STEP_PASSWORD: "password",
    STEP_NEW_PASSWORD: "newPassword",
    STEP_RENEW: "renewPassword",
    STEP_LEGAL: "checkLegal",
    STEP_PROFILE: "profile"
}

