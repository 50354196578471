import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Container } from "react-bootstrap";
import { history } from "./_helpers";
import Routes from "./routes/routes";
import Header from "./components/Common/headerComponent";
import './asset/css/App.scss';
import "bootstrap/dist/css/bootstrap.min.css";

export default function App() {
    return (
       <Router history={history}>
           <Container fluid>
               <Header />
               <Routes />
           </Container>
        </Router>
    );
}



