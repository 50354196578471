import  React from  "react";
import { Route, Redirect } from  "react-router-dom";
import {useSelector} from "react-redux";
import { URL_PAGE_NAME } from "../_constants/configConstants";

const  PrivateRoute = (props) => {

    const { isLoggedIn } = useSelector(state => state.authReducer );

    return  isLoggedIn
        ? (<Route  path={props.path}  exact={props.exact} component={props.component} />)
        : (<Redirect  to={URL_PAGE_NAME.login}  />);
};

export  default  PrivateRoute;