import {coachingConstants} from '../_constants';


const initialState = {
    onRdvGetProgress: false,
    rdv: null,
    rdvRequested: false,
    onRdvGetSlotsProgress: false,
    slots: null,
    slotsRequested: false,
    slotsFailure: false,
    onRdvConfirmationProgress: false,
    rdvConfirmation: null,
    rdvConfirmationRequested: false,
    onRdvRemoveProgress: false,
    rdvRemove: false,
    rdvRemoveRequested: false,
    onRdvReservationProgress: false,
    rdvReservation: false,
    rdvReservationRequested: false
}

export const coachingReducer = ( state = initialState, action ) => {
    switch (action.type){
        case coachingConstants.RDV_UPDATE:
            return {
                ...state,
                rdv: null
            }
        case coachingConstants.RDV_GET_REQUEST:
            return {
                ...state,
                onRdvGetProgress: true,
                rdvRequested: false,
                slotsRequested: false,
                rdvReservationRequested: false,
                rdvConfirmationRequested: false
            }
        case coachingConstants.RDV_GET_SUCCESS:
            return {
                ...state,
                onRdvGetProgress: false,
                rdv: action.rdv,
                rdvRequested: true,
            }
        case coachingConstants.RDV_GET_FAILURE:
            return {
                ...state,
                onRdvGetProgress: false,
                rdvRequested: true,
            }
        case coachingConstants.RDV_GET_SLOTS_REQUEST:
            return {
                ...state,
                onRdvGetSlotsProgress: true,
                slotsRequested: false,
                slotsFailure: false
            }
        case coachingConstants.RDV_GET_SLOTS_SUCCESS:
            return {
                ...state,
                onRdvGetSlotsProgress: false,
                slots: action.slots,
                slotsRequested: true,
                slotsFailure: false
            }
        case coachingConstants.RDV_GET_SLOTS_FAILURE:
            return {
                ...state,
                onRdvGetSlotsProgress: false,
                slotsRequested: true,
                slotsFailure: true
            }
        case coachingConstants.RDV_RESERVATION_REQUEST:
            return {
                ...state,
                onRdvReservationProgress: true,
                rdvReservation: false,
                rdvReservationRequested: false
            }
        case coachingConstants.RDV_RESERVATION_SUCCESS:
            return {
                ...state,
                onRdvReservationProgress: false,
                rdvReservation: action.reservedRdv,
                rdvReservationRequested: true
            }
        case coachingConstants.RDV_RESERVATION_FAILURE:
            return {
                ...state,
                onRdvReservationProgress: false,
                rdvReservation: false,
                rdvReservationRequested: true
            }
        case coachingConstants.RDV_CONFIRMATION_REQUEST:
            return {
                ...state,
                onRdvConfirmationProgress: true,
                rdvConfirmation: null,
                rdvConfirmationRequested: false,
            }
        case coachingConstants.RDV_CONFIRMATION_SUCCESS:
            return {
                ...state,
                onRdvConfirmationProgress: false,
                rdvConfirmation: action.rdv,
                rdvConfirmationRequested: true,
            }
        case coachingConstants.RDV_CONFIRMATION_FAILURE:
            return {
                ...state,
                onRdvConfirmationProgress: false,
                rdvConfirmation: false,
                rdvConfirmationRequested: true,
            }
        case coachingConstants.RDV_REMOVE_REQUEST:
            return {
                ...state,
                onRdvRemoveProgress: true,
                rdvRemove: false,
                rdvRemoveRequested: false,
                rdvReservation: false,
                rdvReservationRequested: false
            }
        case coachingConstants.RDV_REMOVE_SUCCESS:
            return {
                ...state,
                onRdvRemoveProgress: false,
                rdvRemove: true,
                rdvRemoveRequested: true,
            }
        case coachingConstants.RDV_REMOVE_FAILURE:
            return {
                ...state,
                onRdvRemoveProgress: false,
                rdvRemove: false,
                rdvRemoveRequested: true,
            }
        default:
            return state;
    }
}