import AxiosInstance from "./axiosServices";
import {EndPointCustomerHub} from "../_constants";

const getRdv = (idCustomer) => {
    const data = {
        idCustomer: idCustomer
    }
    return AxiosInstance.post(EndPointCustomerHub.getRdv, data)
        .then(response => {
            return response.data
        })
}

const getSlots = (data) => {
    return AxiosInstance.post(EndPointCustomerHub.getSlots, data)
        .then(response => {
            return response.data
        })
}

const rdvReservation = (data) => {
    return AxiosInstance.post(EndPointCustomerHub.reservationRdv, data)
        .then(response => {
            return response.data
        })
}

const rdvRemove = (data) => {
    return AxiosInstance.post(EndPointCustomerHub.removeRdv, data)
        .then(response => {
            return response.data
        })
}

const rdvConfirmation = (data) => {
    return AxiosInstance.post(EndPointCustomerHub.confirmationRdv, data)
        .then(response => {
            return response.data
        })
}

export const coachingServices = {
    getRdv,
    getSlots,
    rdvReservation,
    rdvRemove,
    rdvConfirmation
}