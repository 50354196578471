import { activitiesConstants } from "../_constants";

const initialState = { active: false, showSeance: false, processing: false, hasActivity: false, hasDefault: false, activity: "body", level: 1, coach: "lea"};

const activityReducer = (state = initialState, action ) => {
    switch (action.type){
        case activitiesConstants.ACTIVITY_SHOW_SEANCE:
            return {
                ...state,
                showSeance: action.status
            }
        case activitiesConstants.ACTIVITY_REQUEST:
            return {
                ...state,
                hasActivity: false,
                hasDefault: false,
                processing: true
            }
        case activitiesConstants.ACTIVITY_SUCCESS:
            return {
                ...state,
                activity: action.activity.activity,
                level: action.activity.level,
                coach: action.activity.coach,
                hasActivity: true,
                hasDefault: false,
                processing: false
            }
        case activitiesConstants.ACTIVITY_DEFAULT:
            return{
                ...state,
                processing: false,
                hasActivity: false,
                hasDefault: true,
            }
        case activitiesConstants.ACTIVITY_FAILED:
            return {
                ...state,
                hasActivity: false,
                processing: false,
                hasDefault: true,
                error: action.error
            }
        case activitiesConstants.ACTIVITY_SELECTED:
            return {
                ...state,
                activity: action.activity
            };
        case activitiesConstants.ACTIVITY_LEVEL:
            return {
                ...state,
                level: action.level
            }
        case activitiesConstants.ACTIVITY_COACH:
            return {
                ...state,
                coach: action.coach
            }
        default:
            return state
    }
}

export { activityReducer };