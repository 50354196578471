export const EndPointCustomerHub = {
    login: "/customer/login",
    info: "/customer/info",
    me: '/customer/me',
    updateCustomer: '/customer/update',
    checkMail: "/customer/check-email",
    resetPassword: "/customer/reset-password",
    updatePassword: "/customer/update-password",
    log: "/log",
    getCmd: "/command/customer",
    getRdv: "/rdv/customer",
    getSlots: "/rdv/available",
    reservationRdv: "/rdv/reservation",
    removeRdv: "/rdv/reservation/remove",
    confirmationRdv: "/rdv/confirmation"
}


export const URL_PAGE_NAME = {
    login: "/connexion",
    register: "/inscription",
    recovery: "/mot-de-passe",
    home: "/accueil",
    success: "/mes-reussites",
    program: "/mon-programme",
    habit: "/mes-bonnes-habitudes",
    profile: "/mon-profil",
    activities: "/mes-activites-physiques",
}

export const genderConstant = {
    FEMALE: "female",
    MALE: "male"
}

export const LegalDocumentName = {
    cgu: "cgv.pdf",
    cgv: "cgv.pdf",
    rgpd: "cgv.pdf"
}

export const MAIL_CUSTOMER_SERVICE = "serviceclients@commejaime.fr";
export const PHONE_CUSTOMER_SERVICE = "03 66 06 02 00";
export const ORIGIN = "customerSpace";