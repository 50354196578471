import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import store from './App/_helpers/store';

import App from './App/App';
// console.log('Initial state: ', store.getState())
ReactDOM.render(
      <Provider store={store}>
          <App />
      </Provider>,
  document.getElementById('root')
);
