import React from "react";
import { Switch, Route } from 'react-router-dom';
import { URL_PAGE_NAME } from "../_constants";

import Loadable from 'react-loadable';
import Loading  from '../components/Common/loaderComponent';
import PrivateRoute from "./privateRoute";

const LoadableLogin = Loadable({
    loader: () => import("../pages/Login/loginPage"),
    loading: Loading
})
const LoadableRegister = Loadable({
    loader: () => import("../pages/Register/registerPage"),
    loading: Loading
})
const LoadableRecovery = Loadable({
    loader: () => import("../pages/Recovery/recoveryPage"),
    loading: Loading
})

const LoadableHome = Loadable({
    loader: () => import('../pages/Home/homePage'),
    loading: Loading
})

const LoadableSuccess = Loadable({
    loader: () => import("./../pages/Success/successPage"),
    loading: Loading
})

const LoadableProgram = Loadable({
    loader: () => import("./../pages/Program/programPage"),
    loading: Loading
})

const LoadableHabit = Loadable({
    loader: () => import("./../pages/Habit/habitPage"),
    loading: Loading
})

const LoadableProfile = Loadable({
    loader: () => import("./../pages/Profile/profilePage"),
    loading: Loading
})

const LoadableActivities = Loadable({
    loader: () => import("./../pages/Activities/activitiesPage"),
    loading: Loading
})

export default function Routes(){
    return(
        <Switch>
            <Route exact path="/" component={LoadableLogin} />
            <PrivateRoute path={URL_PAGE_NAME.home} component={LoadableHome} />
            <PrivateRoute path={URL_PAGE_NAME.success} component={LoadableSuccess} />
            <PrivateRoute path={URL_PAGE_NAME.activities} component={LoadableActivities} />
            <PrivateRoute path={URL_PAGE_NAME.program} component={LoadableProgram} />
            <PrivateRoute path={URL_PAGE_NAME.habit} component={LoadableHabit} />
            <PrivateRoute path={URL_PAGE_NAME.profile} component={LoadableProfile} />
            <Route path={URL_PAGE_NAME.recovery} component={LoadableRecovery} />
            <Route path={URL_PAGE_NAME.register} component={LoadableRegister} />
            <Route component={LoadableLogin} />
        </Switch>
    )
}