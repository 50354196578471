import {cmdConstants} from "../_constants";
import {cmdServices} from "../_services/cmdServices";

const getCmdAction = (data) => {
    return dispatch => {
        dispatch(request())

        cmdServices.getCmd(data)
            .then(
                response => {
                    if( response[0] ){
                        dispatch(success(response))
                    }else{
                        dispatch(failure("No cmd found"))
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            )

    }
    function request() { return { type: cmdConstants.CMD_GET_REQUEST}}
    function success(cmd) { return { type: cmdConstants.CMD_GET_SUCCESS, cmd }}
    function failure(errors) { return { type: cmdConstants.CMD_GET_FAILURE, errors}}
}

export const cmdActions = {
    getCmdAction
}