export const coachingConstants = {
    RDV_UPDATE: 'RDV_UPDATE',

    RDV_GET_REQUEST: 'RDV_GET_REQUEST',
    RDV_GET_SUCCESS: 'RDV_GET_SUCCESS',
    RDV_GET_FAILURE: 'RDV_GET_FAILURE',

    RDV_GET_SLOTS_REQUEST: 'RDV_GET_SLOTS_REQUEST',
    RDV_GET_SLOTS_SUCCESS: 'RDV_GET_SLOTS_SUCCESS',
    RDV_GET_SLOTS_FAILURE: 'RDV_GET_SLOTS_FAILURE',

    RDV_CONFIRMATION_REQUEST: 'RDV_CONFIRMATION_REQUEST',
    RDV_CONFIRMATION_SUCCESS: 'RDV_CONFIRMATION_SUCCESS',
    RDV_CONFIRMATION_FAILURE: 'RDV_CONFIRMATION_FAILURE',

    RDV_REMOVE_REQUEST: 'RDV_REMOVE_REQUEST',
    RDV_REMOVE_SUCCESS: 'RDV_REMOVE_SUCCESS',
    RDV_REMOVE_FAILURE: 'RDV_REMOVE_FAILURE',

    RDV_RESERVATION_REQUEST: 'RDV_RESERVATION_REQUEST',
    RDV_RESERVATION_SUCCESS: 'RDV_RESERVATION_SUCCESS',
    RDV_RESERVATION_FAILURE: 'RDV_RESERVATION_FAILURE'
}