import AxiosInstance from "../_services/axiosServices";
import { EndPointCustomerHub, ORIGIN } from '../_constants';
// import  { authHeader }  from "../_helpers/authHeader";
//7c222fb2927d828af22f592134e8932480637c0d

const resetPassword = (email, resetToken ) => {
    const data = {
        email: email,
        reset_token: resetToken
    }

    return AxiosInstance.post(EndPointCustomerHub.resetPassword, data)
        .then((response) => {
            return response.data;
        })
}

const newPasswordRequest = (newPassword, token) => {
    const data = {
        "password": newPassword,
        "token": token
    }

    return AxiosInstance.patch(EndPointCustomerHub.updatePassword, data)
        .then((response) => {
            if( response.data.numero_client ){
                localStorage.setItem("passwordType", 1);
            }
            return response.data;
        })
}

const checkEmailRequest = (email) => {
    const data = {
        "email": email
    }

    return AxiosInstance.post(EndPointCustomerHub.checkMail, data)
        .then((response) => {
            if (response.data.reset_token) {
                localStorage.setItem("token", JSON.stringify(response.data.reset_token));
            }
            return response.data;
        })
}

const loginRequest = (username, password) => {

    const data = {
        "email": username,
        "password": password
    }

    return AxiosInstance.post( EndPointCustomerHub.login, data)
        .then((response) => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }
            return response.data;
        })
}

const logoutRequest = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("passwordType");
    localStorage.removeItem("infos");
};

const getCustomerRequest = (externalId) => {

    const customerId = {
        "id": externalId
    }

    return AxiosInstance.post( EndPointCustomerHub.info, customerId)
        .then((customer) => {
            if (customer.data.id) {
                const infos = {
                    passwordType: customer.data.passwordType ? customer.data.passwordType.type_id : 1,
                    checkCgu: false,
                    checkData: false
                }
                localStorage.setItem("passwordType", JSON.stringify(customer.data.passwordType ? customer.data.passwordType.type_id : 1));
                localStorage.setItem("infos", JSON.stringify(infos));

                const user = JSON.parse(localStorage.getItem("user"));
                user.firstname = customer.data.firstname;
                user.externalId = customer.data.externalId;
                localStorage.setItem("user", JSON.stringify(user));

                return customer.data;
            }
            return null;
        });
}

const updateLegalMention = (customerId) => {
    const data = {
        origin: ORIGIN,
        message: {
            customer: customerId,
            cgu: true,
            data: true,
            date: new Date().toLocaleString()
        },
        code: "0000"
    }

    return AxiosInstance.post( EndPointCustomerHub.log, data)
        .then((response) => {
            if( parseInt(response.data) === 201 ){
                const infos = JSON.parse(localStorage.getItem("infos"));
                infos.checkCgu = true;
                infos.checkData = true;
                localStorage.setItem("infos", JSON.stringify(infos));
            }
            return response.data;
        })
}

export const userService = {
    loginRequest,
    logoutRequest,
    getCustomerRequest,
    checkEmailRequest,
    newPasswordRequest,
    resetPassword,
    updateLegalMention
};