import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {customerActions} from '../../_actions';
import {Col, Row} from "react-bootstrap";
import * as IoIcons from "react-icons/io";
import {ReactComponent as Logo} from "../../asset/image/CommeJaime_logo.svg";
import '../../asset/css/header.scss';

const Header = () => {

    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector(state => state.authReducer );

    const logout = () =>{
        dispatch( customerActions.logoutAction());
    }

    return (
        <Row className="header-block">
            <Col xs={7} sm={"auto"} >
                <Logo />
            </Col>
            <Col xs={{span:12, order:2}} sm={{span:"auto", order:1}}>
                <span className="d-none d-sm-inline size30">|</span>
                <span className="d-none d-sm-inline magentaColor size25 ml-3">mon espace personnel</span>
            </Col>
            { isLoggedIn &&
            <Col className="icon-block ml-auto" xs={{span:5,order:1}} sm={{span:2,order:3}} xl={1}>
                    <div className="btn-icon ml-2">
                        <a href="/" title="Logout" onClick={logout}>
                            <IoIcons.IoMdLogOut />
                        </a>
                    </div>
            </Col>
            }
        </Row>
    )
}

export default Header;