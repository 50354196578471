import { customerConstants } from '../_constants';

const customer  = JSON.parse(localStorage.getItem("passwordType"));
const infos  = JSON.parse(localStorage.getItem("infos"));

const initialState = customer
    ? {
        onProgress: false,
        onProgressCustomer: false,
        customerRequested: true,
        passwordType: customer.passwordType,
        newPasswordRequested: false,
        newPasswordChanged: false,
        resetOnProgress: false,
        resetPasswordRequested: false,
        resetPasswordChanged: false,
        checkOnProgress: false,
        checkUpdated: false,
        checkCgu: infos.checkCgu,
        checkData: infos.checkData,
        profile: infos.profile
    }
    : {
        onProgress: false,
        onProgressCustomer: false,
        customerRequested: false,
        passwordType: null,
        newPasswordRequested: false,
        newPasswordChanged: false,
        resetOnProgress: false,
        resetPasswordRequested: false,
        resetPasswordChanged: false,
        checkOnProgress: false,
        checkUpdated: false,
        checkCgu: null,
        checkData: null,
        profile: null
    }

export function customerReducer( state = initialState, action) {
    switch (action.type) {
        case customerConstants.LEGAL_CHECK_UPDATE_REQUEST:
            return {
                ...state,
                checkOnProgress: true,
                checkUpdated: false
            }
        case customerConstants.LEGAL_CHECK_UPDATE_SUCCESS:
            return {
                ...state,
                checkOnProgress: false,
                checkUpdated: true,
                checkCgu: true,
                checkData: true,
            }
        case customerConstants.LEGAL_CHECK_UPDATE_FAILURE:
            return {
                ...state,
                checkOnProgress: false,
                checkUpdated: false
            }
        case customerConstants.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                resetOnProgress: true,
                resetPasswordRequested: false,
                resetPasswordChanged: false
            }
        case customerConstants.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetOnProgress: false,
                resetPasswordRequested: true,
                resetPasswordChanged: true
            }
        case customerConstants.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resetOnProgress: false,
                resetPasswordRequested: true,
                resetPasswordChanged: false
            }
        case customerConstants.NEW_PASSWORD_REQUEST:
            return {
                ...state,
                newPasswordRequested: false,
                newPasswordChanged: false,
                onProgress: true
            }
        case customerConstants.NEW_PASSWORD_SUCCESS:
            return {
                ...state,
                onProgress: false,
                newPasswordRequested: true,
                newPasswordChanged: true,
                passwordType: action.passwordType
            }
        case customerConstants.NEW_PASSWORD_FAILURE:
            return {
                ...state,
                onProgress: false,
                newPasswordRequested: true,
                newPasswordChanged: false
            }
        case customerConstants.CUSTOMER_REQUEST:
            return {
                ...state,
                customerRequested: false,
                onProgressCustomer: true
            };
        case customerConstants.CUSTOMER_SUCCESS:
            return {
                ...state,
                onProgressCustomer: false,
                customerRequested: true,
                passwordType: action.passwordType
            };
        case customerConstants.CUSTOMER_FAILURE:
            return {
                ...state,
                onProgressCustomer: false,
                customerRequested: true,
                error: action.error
            };
        case customerConstants.DELETE_CUSTOMER:
            return{
                ...state,
                customerRequested: false,
                customer: null
            }
        default:
            return state
    }
}