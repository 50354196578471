import AxiosInstance from "./axiosServices";
import {EndPointCustomerHub} from "../_constants";

const getCmd = (data) => {
    return AxiosInstance.post(EndPointCustomerHub.getCmd, data)
        .then(response => {
            return response.data
        })
}

export const cmdServices = {
    getCmd
}