import AxiosInstance from "../_services/axiosServices";
import { EndPointCustomerHub } from '../_constants';

const profileCustomerUpdate = (infos) => {
    const data = {
        "token": infos.token,
        "firstname": infos.firstname,
        "lastname": infos.lastname,
        "gender": infos.gender,
        "birthdate": infos.birthdate
    }
    return AxiosInstance.patch(EndPointCustomerHub.updateCustomer, data)
        .then((response) => {
            return response.data;
        })
}

const profileCustomerRequest = ( token ) => {
    const data = {
        "token": token
    }

    return AxiosInstance.post(EndPointCustomerHub.me, data)
        .then((response) => {
            return response.data;
        })
}

export const profileServices = {
    profileCustomerRequest,
    profileCustomerUpdate
}