import { SET_MESSAGE, CLEAR_MESSAGE, messageConstants } from "../_constants";

export const setMessage = (message) => ({
    type: SET_MESSAGE,
    payload: message
});

export const clearMessage = () => ({
    type: CLEAR_MESSAGE,
});

export const messageActions = {
    success,
    error,
    clear
};

function success(message) {
    return { type: messageConstants.SUCCESS, message };
}

function error(message) {
    return { type: messageConstants.ERROR, message };
}

function clear() {
    return { type: messageConstants.CLEAR };
}