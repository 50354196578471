export const profileMenu = {
    DATA: 'data',
    CMD: 'cmd',
    HELP: 'help'
}

export const profileConstants = {
    SET_MENU: 'SET_MENU',

    INFOS_CUSTOMER_REQUEST: 'INFOS_CUSTOMER_REQUEST',
    INFOS_CUSTOMER_SUCCESS: 'INFOS_CUSTOMER_SUCCESS',
    INFOS_CUSTOMER_FAILURE: 'INFOS_CUSTOMER_FAILURE',

    CUSTOMER_UPDATE_REQUEST: 'CUSTOMER_UPDATE_REQUEST',
    CUSTOMER_UPDATE_SUCCESS: 'CUSTOMER_UPDATE_SUCCESS',
    CUSTOMER_UPDATE_FAILURE: 'CUSTOMER_UPDATE_FAILURE',
    CUSTOMER_UPDATE_RAZ: 'CUSTOMER_UPDATE_RAZ',

    CMD_GET_REQUEST: 'CMD_GET_REQUEST',
    CMD_GET_SUCCESS: 'CMD_GET_SUCCESS',
    CMD_GET_FAILURE: 'CMD_GET_FAILURE',

}