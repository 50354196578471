import { customerConstants, loginStep } from '../_constants/';

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
    ? {
        hasStep: loginStep.STEP_EMAIL,
        loginRequested: false,
        emailRequested: false,
        emailChecked: false,
        onProgress: false,
        isLoggedIn: true,
        resetToken: null,
        user: user
        }
    : {
        hasStep: loginStep.STEP_EMAIL,
        loginRequested: false,
        emailRequested: false,
        emailChecked: false,
        onProgress: false,
        isLoggedIn: false,
        resetToken: null,
        user: null
    };

export const authReducer = ( state = initialState, action ) => {

    switch (action.type) {
        case customerConstants.SET_STEP:
            return {
                ...state,
                hasStep: action.step
            }
        case customerConstants.CHECK_EMAIL_REQUEST:
            return {
                ...state,
                onProgress: true,
                emailRequested: false,
            }
        case customerConstants.CHECK_EMAIL_SUCCESS:
            return {
                ...state,
                onProgress: false,
                emailRequested: true,
                emailChecked: action.email,
                resetToken: action.token,
                hasStep: loginStep.STEP_PASSWORD
            }
        case customerConstants.CHECK_EMAIL_FAILURE:
            return {
                ...state,
                onProgress: false,
                emailRequested: true
            }
        case customerConstants.LOGIN_REQUEST:
            return {
                ...state,
                onProgress: true,
                loginRequested:false,
                user: null
            };
        case customerConstants.LOGIN_SUCCESS:
            return {
                ...state,
                onProgress: false,
                user: action.user,
                loginRequested:true,
                isLoggedIn: true
            };
        case customerConstants.LOGIN_FAILURE:
            return {
                ...state,
                onProgress: false,
                loginRequested:true
            };
        case customerConstants.LOGOUT:
            return {
                ...state
            };
        default:
            return state
    }
}


