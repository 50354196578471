import {messageConstants, customerConstants} from '../_constants';
import { userService } from '../_services';
import { messageActions } from './';

const cleanMessage = () => {return { type: messageConstants.CLEAR }}

const checkEmail = (email) => {
    return dispatch => {
        dispatch(request());
        dispatch(cleanMessage());

        userService.checkEmailRequest(email)
            .then(
                response => {
                    if( response.reset_token ){
                        dispatch(success(response, email))
                    }else{
                        dispatch(failure("no found"));
                        dispatch(messageActions.error(response));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(messageActions.error(error.message));
                }
            );
    };

    function request() { return { type: customerConstants.CHECK_EMAIL_REQUEST } }
    function success(response, email) { return { type: customerConstants.CHECK_EMAIL_SUCCESS, token: response.reset_token , email: email } }
    function failure(error) { return { type: customerConstants.CHECK_EMAIL_FAILURE, error } }
}

function loginAction(username, password) {
    return dispatch => {
        dispatch(request());
        dispatch(cleanMessage());

        userService.loginRequest(username, password)
            .then(
                user => {
                    if( user.token && user.numero_client ){
                        dispatch(success(user));
                    }else{
                        dispatch(failure("no found"));
                        dispatch(messageActions.error(user));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(messageActions.error(error.message));
                }
            );
    };

    function request() { return { type: customerConstants.LOGIN_REQUEST } }
    function success(user) { return { type: customerConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: customerConstants.LOGIN_FAILURE, error } }
}

function getCustomerAction(internalId) {
    return dispatch => {
        dispatch(request());

        return userService.getCustomerRequest(internalId)
            .then(
                customer => {
                    if( customer ){
                        dispatch(success(customer.passwordType ? customer.passwordType.type_id : 1))
                    }else{
                        dispatch(failure("No customer"))
                    }
                },
                error => {
                    dispatch(failure(error.toString()))
                }
            );
    };

    function request() { return { type: customerConstants.CUSTOMER_REQUEST } }
    function success(passwordType) { return { type: customerConstants.CUSTOMER_SUCCESS, passwordType } }
    function failure(error) { return { type: customerConstants.CUSTOMER_FAILURE, error } }
}

const newPasswordAction = (password, token) => {
    return dispatch => {
        dispatch(cleanMessage());
        dispatch(request())

        userService.newPasswordRequest(password, token)
            .then(
                response => {
                    if( response?.numero_client ){
                        const passwordType = 1;
                        dispatch(success(passwordType))
                    }else{
                        dispatch(failure("no id customer"));
                        dispatch(messageActions.error("Une erreur c'est produite"));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(messageActions.error(error.message));
                }
            )
    }

    function request() { return { type: customerConstants.NEW_PASSWORD_REQUEST } }
    function failure(error) { return { type: customerConstants.NEW_PASSWORD_FAILURE, error } }
    function success(passwordType) { return { type: customerConstants.NEW_PASSWORD_SUCCESS, passwordType } }
}

const resetPassword = (email, token) => {
    return dispatch => {
        dispatch(cleanMessage());
        dispatch(request())

        userService.resetPassword(email, token)
            .then(
                response => {
                    if( response.numero_client ){
                        dispatch(success())
                    }else{
                        dispatch(failure("reset password issue"));
                        dispatch(messageActions.error("Une erreur c'est produite"));
                    }

                },
                error => {
                    dispatch(failure(error));
                    dispatch(messageActions.error(error.message));
                }
            )
    }

    function success() { return { type: customerConstants.RESET_PASSWORD_SUCCESS } }
    function failure(error) { return { type: customerConstants.RESET_PASSWORD_FAILURE, error } }
    function request() { return { type: customerConstants.RESET_PASSWORD_REQUEST } }
}

const updateLegalMentionAction = (customerId) => {
    return dispatch => {
        dispatch(cleanMessage());
        dispatch(request())

        userService.updateLegalMention(customerId)
            .then(
                response => {
                    if( response ){
                        dispatch(success())
                    }else{
                        dispatch(failure("log failure"));
                        dispatch(messageActions.error("Une erreur c'est produite"));
                    }
                },
                error => {
                    dispatch(failure(error));
                    dispatch(messageActions.error(error.message));
                }
            )

    }
    function request() { return { type: customerConstants.LEGAL_CHECK_UPDATE_REQUEST } }
    function success() { return { type: customerConstants.LEGAL_CHECK_UPDATE_SUCCESS } }
    function failure(error) { return { type: customerConstants.LEGAL_CHECK_UPDATE_FAILURE, error } }
}

function logoutAction() {

    function logoutCustomer() { return { type: customerConstants.DELETE_CUSTOMER } }
    function logout() { return { type: customerConstants.LOGOUT } }

    return dispatch => {
        dispatch(logout());
        dispatch(logoutCustomer());
        dispatch(cleanMessage());
        userService.logoutRequest();
    }
}

const setStep = (step) => {
    return dispatch => {
        dispatch(setStep(step));
    }

    function setStep(step) { return { type: customerConstants.SET_STEP, step } }
}

export const customerActions = {
    loginAction,
    logoutAction,
    getCustomerAction,
    checkEmail,
    setStep,
    newPasswordAction,
    resetPassword,
    updateLegalMentionAction
};